export default class API {
  static DEV_URL = "http://localhost:3000";
  static PROD_URL = "https://be.asking.codes";
  static BASE_URL =
    process.env.NODE_ENV === "development" ? API.DEV_URL : API.PROD_URL;

  static get<T>(path: string): Promise<T> {
    return fetch(`${API.BASE_URL}${path}`).then(
      (res) => res.json() as unknown as T
    );
  }
}
