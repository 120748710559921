import * as React from "react";
import "./App.css";
import MemeOfTheDay from "./MemeOfTheDay/MemeOfTheDay";

export default function App() {
  const memeOfTheDay = MemeOfTheDay();

  return (
    <div className="App">
      <header className="App-header">
        <h2>asking.codes</h2>
        <div className="Status">🚧👷🏻‍♀️🛠️</div>
        <h2>coming soon™️</h2>
      </header>
      {memeOfTheDay}
      <div className="App-footer" />
    </div>
  );
}