import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./app/App";
import * as React from "react";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// import reportWebVitals from './reportWebVitals';
//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//
// reportWebVitals();
