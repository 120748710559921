import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { MemeAPI, Meme, MediaType } from "../API/Meme/API+Meme";

import "../../style/colors/background.css";
import "../../style/fonts/stylesheet.css";
import "./MemeOfTheDay.css";

export default function MemeOfTheDay() {
  const [meme, setMeme] = useState<Meme | null | undefined>(undefined);

  const getImageURL = useCallback(() => {
    if (meme !== undefined) {
      return;
    }
    MemeAPI.getMeme()
      .then(setMeme)
      .catch((e) => {
        console.error(e);
        setMeme(null);
      });
  }, [meme]);

  useEffect(() => {
    getImageURL();
  }, [getImageURL]);

  let mediaComponent;
  if (meme) {
    mediaComponent = makeMediaComponent(meme);
  }

  return (
    <div>
      <p className="Header">Meme of the day 👇</p>
      <div className="container">{mediaComponent}</div>
    </div>
  );
}

function makeMediaComponent(meme: Meme) {
  switch (meme.type) {
    case MediaType.Image:
      return (
        <picture>
          <img className="Main-image" src={meme.source} alt="Meme of the day" />
        </picture>
      );
    case MediaType.Video:
      return (
        <video
          className="Main-video"
          controls
          autoPlay
          muted
          onPlay={(e) => {
            const element = e.target as HTMLVideoElement;
            element.muted = false;
            element.volume = 0.3;
          }}
        >
          <source
            src={meme.source}
            type={"video/" + getUrlExtension(meme.source)}
          />
        </video>
      );
  }

  function getUrlExtension(url: string): string {
    return url.split(/[#?]/)[0].split(".").pop()?.trim() ?? "mp4";
  }
}
