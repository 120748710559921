import API from "../API";

export type Meme = {
  source: string;
  type: MediaType;
};

export enum MediaType {
  Image = "Image",
  Video = "Video",
}

export class MemeAPI extends API {
  static getMeme(): Promise<Meme> {
    return API.get("/meme-of-the-day");
  }
}
